<template>

<div class="head" v-if="$loggedin" :class="{'is-offset': offset, 'is-empty': !hasConventions}">

	<com-profile :expanded="isAccount" class="head-profile" v-if="window.is.desktop && !noProfile" v-on:toggleAccount="$emit('toggleAccount')" />

	<app-icon class="head-toggle" icon="menu" v-if="!window.is.desktop" v-on:click.native="$emit('toggleMenu')">

		<div class="head-toggle-dot" v-if="$store.getters['session/count/notifications']" />

	</app-icon>

	<com-context v-if="!noContext && hasConventions" /> 

</div>

</template>

<script>

export default {

	props: ['offset', 'noProfile', 'noContext', 'isAccount'],

	components: {
		'com-context': () => import('./head/Context'),
		'com-profile': () => import('./head/Profile')
	},

	computed: {

		hasConventions: function() {

			return this.$store.getters['session/conventions'].length

		}

	}

}

</script>

<style scoped>

.head {
	position: fixed;
	left: 0px; 
	top: 0px;
	width: calc(100% + 320px);
	padding-right: 320px;
	background-color: #2f3a4a;
	height: var(--head-height);
	display: flex;
	z-index: 4;
	align-items: center;
	transition: transform 100ms ease-in-out;
}

.head.is-empty {
	width: 320px;
}

.head.is-offset {
	transform: translateX(-320px);
}

.is-tablet .head {
	height: 64px;
}

.is-mobile .head {
	height: 48px;
}

.head-toggle {
	width: 64px;
	height: 64px;
	color: #fff;
	font-size: 32px;
	text-align: center;
	line-height: 64px;
}

.is-mobile .head-toggle {
	width: 48px;
	height: 48px;
	line-height: 52px;
	font-size: 24px;
}

.head-profile {
	width: 320px;
	background-color: rgba(0, 0, 0, 0.1);
	height: var(--head-height);
}

.head-title {
	color: #fff;
	font-size: 32px;
	padding: 0px 20px;
	font-weight: 400;
	display: flex;
	align-items: center;
}

.is-tablet .head-title {
	font-size: 24px;
	padding: 0px 10px;
}

.is-mobile .head-title {
	font-size: 20px;
	padding: 0px 0px;
}

.head-title-logo {
	width: 64px;
	height: 64px;
	background-size: contain;
	background-color: #fff;
	border-radius: 10px;
	background-repeat: no-repeat;
	border: 4px solid #fff;
	margin-right: 10px;
}

.is-mobile .head-title-logo {
	display: none;
}

.head-title small {
	font-weight: 300;
	font-size: 16px;
	margin-top: 4px;
	display: block;
}

.is-tablet .head-title small {
	font-size: 14px;
}

.is-mobile .head-title small {
	font-size: 12px;
}

.head-toggle-dot {
	position: absolute;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #dd4848;
	right: 8px;
	top: 8px;
}

</style>
